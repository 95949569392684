<template>
  <TTView>
    <VRow>
      <VCol>
        <VCard>
          <VToolbar flat>
            <VBtn
              icon
              @click="$router.go(-1)"
            >
              <VIcon>fal fa-chevron-left</VIcon>
            </VBtn>

            <VToolbarTitle>Создание топика</VToolbarTitle>
          </VToolbar>
          <VCardText>
            <VForm ref="topicForm">
              <VRow>
                <VCol cols="6">
                  <VTextField
                    v-model="name"
                    label="Название топика"
                  />

                  <VBtn
                    depressed
                    color="primary"
                    @click="createTopic(testId)"
                  >
                    Создать
                  </VBtn>
                </VCol>
              </VRow>
            </VForm>
          </VCardText>
        </VCard>
      </VCol>
    </VRow>
  </TTView>
</template>

<script>
export default {
  name: 'TopicCreate',
  inject: ['Names'],
  data() {
    return {
      name: '',
    };
  },
  computed: {
    testId() {
      return this.$route.params.testId;
    },
  },
  methods: {
    async createTopic(testId) {
      try {
        await this.$di.api.Talent.topicCreate({ testId, name: this.name });
        this.$di.notify.snackSuccess('Топик успешно создан');

        this.clear();
      } catch (error) {
        this.$di.notify.errorHandler(error);
      }
    },
    clear() {
      this.$refs.topicForm.reset();
    },
  },
};
</script>
